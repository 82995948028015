import React, { useState } from 'react'
import { FieldControl, ButtonControl } from 'spark-core-dx/components';
import { useWizardDomainContext } from '../WizardDomainProvider';

const WizardPage = ({ pageInfo, children }) => {
  const { handleOnSave, currentStep, setCurrentStep } = useWizardDomainContext();

  const renderUpdatedSequence = (stepNumber) => {
    if (stepNumber >= 6) {
      return stepNumber - 1;
    } else return stepNumber
  }

  return (
    <div className="wizard-page">
      <div className="wizard-title">
        <h2>{pageInfo?.id ? renderUpdatedSequence(pageInfo.id) + "." : ""} {pageInfo?.title}</h2>
        <p>{pageInfo?.subtitle}</p>
      </div>
      {children}
    </div>
  )
}

export default WizardPage