import React, { useState } from 'react';
import Accordion from '../components/Accordion';
import { FieldControl, Loading } from 'spark-core-dx/components';
// "companyId={CompanyId};senderId={_senderId};senderPassword={_senderPassword};userId={_userId};userPassword={_userPassword}";

const SageSetup = ({ data, appName, errorApps, updateData, }) => {

    return (
        <Accordion
            key={appName}
            title={appName}
            opened={errorApps.includes(appName.toLowerCase())}
        >
            <FieldControl
                key={'companyId'}
                groupId={'application'}
                fieldMetadata={{
                    FieldType: 1,
                    FieldName: `companyId`,
                    DisplayName: 'Company ID',
                    IsRequired: true,
                }}
                value={data?.companyId}
                onChange={updateData}
            />
            <FieldControl
                key={'senderId'}
                groupId={'application'}
                fieldMetadata={{
                    FieldType: 1,
                    FieldName: `senderId`,
                    DisplayName: 'Sender ID',
                    IsRequired: true,
                }}
                value={data?.senderId}
                onChange={updateData}
            />
            <FieldControl
                key={'senderPassword'}
                groupId={'application'}
                fieldMetadata={{
                    FieldType: 3,
                    FieldName: `senderPassword`,
                    DisplayName: 'Sender Password',
                    IsRequired: true,
                }}
                value={data?.senderPassword}
                onChange={updateData}
            />
            <FieldControl
                key={'userId'}
                groupId={'application'}
                fieldMetadata={{
                    FieldType: 1,
                    FieldName: `userId`,
                    DisplayName: 'User ID',
                    IsRequired: true,
                }}
                value={data?.userId}
                onChange={updateData}
            />
            <FieldControl
                key={'userPassword'}
                groupId={'application'}
                fieldMetadata={{
                    FieldType: 3,
                    FieldName: `userPassword`,
                    DisplayName: 'User Password',
                    IsRequired: true,
                }}
                value={data?.userPassword}
                onChange={updateData}
            />

        </Accordion>
    );
};

export default SageSetup;