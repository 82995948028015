import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { FieldControl } from 'spark-core-dx/components';
import { useCoreContext } from 'spark-core-dx/contexts'
import { useGenericManager, useValidationErrors } from 'spark-core-dx/hooks';
import { useWizardDomainContext } from '../../WizardDomainProvider';
import { ItemServiceFactory } from '../../../../services/ItemServiceFactory';
import WizardFooter from './WizardFooter';

const Branding = ({ themeItem, setThemeItem, setCurrentTab, setChanges }) => {
    const { cid } = useParams();
    const coreContext = useCoreContext();
    const itemService = new ItemServiceFactory()
    const { clientItem, setClientItem, handleProgress, updateOnboardingProcess, onboardingItem } = useWizardDomainContext();
    const { hasAndShowValidationErrors } = useValidationErrors();
    const themeMgr = useGenericManager(itemService.ItemServiceEnum.Theme, itemService);
    const clientMgr = useGenericManager(itemService.ItemServiceEnum.Client, itemService);

    const [colorSelection, setColorSelection] = useState({})

    const [domainDisabled, setDomainDisabled] = useState(onboardingItem.SystemConfigurationCompleted !== null)

    const [domainsList, setDomainsList] = useState([])
    const [customURL, setCustomURL] = useState('https://')
    const [origCustomURL, setOrigCustomURL] = useState('https://')

    const [origColorSelection, setOrigColorSelection] = useState({})

    useEffect(() => {
        if (clientItem?.ClientDomains?.length > 0) {
            const renderDomainsList = clientItem?.ClientDomains?.map((dom) => dom.Domain)
            const containsNonAbmfg = renderDomainsList.find(domain => !domain.includes("abmfg.com"));
            if (containsNonAbmfg) {
                setCustomURL(containsNonAbmfg)
                setOrigCustomURL(containsNonAbmfg);
            }
            setDomainsList(renderDomainsList);

        }
    }, [clientItem?.ClientDomains])

    useEffect(() => {
        const updatedDomainsList = [];
        updatedDomainsList.push(`https://${coreContext.Launch?.Env === "PROD" ? "" : coreContext.Launch?.Env?.toLowerCase() + "."}${clientItem?.ShortName.toLowerCase()}.abmfg.com`);

        if (customURL && customURL !== "https://") {
            updatedDomainsList.push(customURL);
        }
        setDomainsList(updatedDomainsList);
    }, [customURL]);

    useEffect(() => {
        if (themeItem?.SparkAppStyleSheet) {
            const primaryMatch = themeItem?.SparkAppStyleSheet.match(/--primary:\s*([^;]+);/);
            const secondaryMatch = themeItem?.SparkAppStyleSheet.match(/--secondary:\s*([^;]+);/);
            const tertiaryMatch = themeItem?.SparkAppStyleSheet.match(/--tertiary:\s*([^;]+);/);
            const primary = primaryMatch ? primaryMatch[1].trim() : "#00356B";
            const secondary = secondaryMatch ? secondaryMatch[1].trim() : "#29ABE2";
            const tertiary = tertiaryMatch ? tertiaryMatch[1].trim() : "#D8D8D8";
            setColorSelection({ primary, secondary, tertiary })
            setOrigColorSelection({ primary, secondary, tertiary })
        }
    }, [themeItem])

    useEffect(() => {
        setChanges(hasChanges())
    }, [colorSelection, customURL])

    const hasChanges = () => {
        const urlChanged = JSON.stringify(origCustomURL) !== JSON.stringify(customURL);
        const colorsChanged = JSON.stringify(colorSelection) !== JSON.stringify(origColorSelection);

        return urlChanged || colorsChanged;
    };

    const handleProgressUpdate = () => {
        const primaryCount = colorSelection.primary ? 1 : 0;
        const portalDomainCount = (domainsList.length > 0)
        const totalFilled = primaryCount + portalDomainCount;
        const totalCount = 2

        return { totalFilled, totalCount }
        // handleProgress(totalFilled, totalCount, 'page13');
    }

    const createDomainItemsArray = (domainsList) => {
        // UniqueId is made from the url, appId, and environment so don't need to grab old item to update.
        return domainsList.map((url) => ({
            RegisteredAppId: coreContext?.Profile?.AppId,
            Environment: coreContext?.Launch?.Env,
            Domain: url,
        }));
    };

    const handleCustomValidate = (value) => {
        if (!value || value === "https://") {
            return true;
        }

        const urlPattern = /^https:\/\/([\w-]+(\.[\w-]+)+)([/?].*)?$/;
        if (!urlPattern.test(value)) {
            return `Please enter a valid URL (beginning with 'https://').`;
        }
        return true;
    }

    const handleColor = (type, value) => {
        setColorSelection(prev => {
            return { ...prev, [type]: value }
        })
    }

    const handleOnSave = async (isContinue) => {
        if (!hasAndShowValidationErrors('branding')) {
            const { primary, secondary, tertiary } = colorSelection;

            const updatedStyleSheet = `.app {
                --primary: ${primary || "#00356B"};
                --secondary: ${secondary || "#29ABE2"};
                --tertiary: ${tertiary || "#D8D8D8"};
                }`;
            const copiedTheme = {
                ...themeItem,
                Name: `${clientItem.Name}'s Theme`
            }
            copiedTheme.SparkAppStyleSheet = updatedStyleSheet;
            copiedTheme.ClientId = cid;

            const domainItemsArray = createDomainItemsArray(domainsList);

            if (clientItem) {
                // Only save if there's 2 or less domains.
                let copiedClient;
                copiedClient = {
                    ...clientItem,
                    ClientDomains: domainItemsArray
                }

                await clientMgr.saveItem(copiedClient).then(r => {
                    if (r.Success) {
                        let newClient = r.Items.first()
                        setClientItem(newClient)
                    }
                })
            }

            await themeMgr.saveItem(copiedTheme).then(r => {
                if (r.Success) {
                    setThemeItem(r.Items.first())
                    if (isContinue) {
                        setCurrentTab(4)
                    }
                }
            })
            const { totalFilled, totalCount } = handleProgressUpdate()
            await handleProgress(totalFilled, totalCount, 'page13', updatedPageValues => {
                updateOnboardingProcess(1, updatedPageValues);
            });
        }
    }

    const renderColors = () => {
        return (
            <div className="branding-colors">
                <FieldControl
                    key={"primary"}
                    groupId={'branding'}
                    fieldMetadata={{
                        FieldType: 6,
                        FieldName: "Primary",
                        DisplayName: "Primary",
                        IsRequired: true,
                    }}
                    validationMessage={'Please select a primary color.'}
                    value={colorSelection['primary']} // Don't set a default for this to force them to choose one.
                    onChange={(value) => handleColor('primary', value)}
                />
                <FieldControl
                    key={"secondary"}
                    groupId={'branding'}
                    fieldMetadata={{
                        FieldType: 6,
                        FieldName: "Secondary",
                        DisplayName: "Secondary",
                        IsRequired: false,
                    }}
                    validationMessage={'Please select a secondary color.'}
                    value={colorSelection['secondary'] ?? '#29ABE2'}
                    onChange={(value) => handleColor('secondary', value)}
                />
                <FieldControl
                    key={"tertiary"}
                    groupId={'branding'}
                    fieldMetadata={{
                        FieldType: 6,
                        FieldName: "Tertiary",
                        DisplayName: "Tertiary",
                        IsRequired: false,
                    }}
                    validationMessage={'Please select a tertiary color.'}
                    value={colorSelection['tertiary'] ?? '#D8D8D8'}
                    onChange={(value) => handleColor('tertiary', value)}
                />
            </div>
        )
    }

    const renderPortalDomain = () => {
        return (
            <div className="branding-portal">
                <div className="warning">
                    <p>
                        Once the portal domain is established during the Franchisee Account Setup, it cannot be changed via the wizard process.
                    </p>
                    <p>
                        If changes are needed, then a ticket will need to be submitted.
                    </p>
                </div>
                <div className={`domain-container${domainDisabled ? " disabled" : ""}`}>
                    <div className='domain-text-container'>
                        <p>
                            Your ABMFG Subdomain is
                            <span>
                                https://{coreContext.Launch?.Env === "PROD" ? "" : coreContext.Launch?.Env?.toLowerCase() + "."}{clientItem?.ShortName.toLowerCase()}.abmfg.com.
                            </span>
                        </p>
                        <p>
                            This domain was created using the short name provided previously in the Wizard.
                        </p>
                    </div>
                    <div className='domain-text-container'>
                        <p>Would you like to also use a Custom URL?</p>
                        <p>If so, enter the Custom URL below. It not, this step can be skipped.</p>

                        <FieldControl
                            key={'custom-subdomain'}
                            groupId={'branding'}
                            fieldMetadata={{
                                FieldType: 1,
                                FieldName: 'CustomSubdomain',
                            }}
                            disabled={domainDisabled}
                            value={customURL ?? 'https://'}
                            onChange={setCustomURL}
                            onCustomValidate={handleCustomValidate}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>

            <div className="wizard-content">
                <h4>
                    Colors
                </h4>
                {renderColors()}
                <div className='title'>
                    <h4>
                        Portal Domain
                    </h4>
                </div>
                {renderPortalDomain()}
            </div>
            <WizardFooter
                showPrevBtn={true}
                prevBtnOnClick={() => setCurrentTab(2)}
                showSecBtn={true}
                primaryBtnOnClick={async () => { await handleOnSave(true) }}
                secBtnOnClick={async () => await handleOnSave()}
                primaryBtnText={"Save & Continue"}
                secBtnText={"Save"} />
        </>
    )
}

export default Branding