import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGenericManager } from 'spark-core-dx/hooks'
import { useCoreStateContext, useCoreContext, useValidationErrors } from 'spark-core-dx/contexts';
import { ItemServiceFactory } from '../../services/ItemServiceFactory'
import _ from 'lodash'
export const WizardDomainContext = createContext();
const defaultPageValues = {
    page1: 0,
    page11: 0,
    page12: 0,
    page13: 0,
    page14: 0,
    page2: 0,
    page3: 0,
    page4: 0,
    page5: 0,
    page51: 0,
    page52: 0,
    page6: 0,
    page7: 0,
    page71: 0,
    page72: 0,
    page73: 0,
    page74: 0,
}
export function WizardDomainContextProvider({ children }) {
    const { hasAndShowValidationErrors } = useValidationErrors();
    const { cid } = useParams();

    const coreState = useCoreStateContext();
    const coreContext = useCoreContext();
    const itemService = new ItemServiceFactory()
    const onboardingMgr = useGenericManager(itemService.ItemServiceEnum.Onboarding, itemService);
    const clientMgr = useGenericManager(itemService.ItemServiceEnum.Client, itemService);
    const [onboardingActiveList, setOnboardingActiveList] = useState(null)
    const [clientItem, setClientItem] = useState(null)
    const [currentStep, setCurrentStep] = useState(0);
    const [currentTab, setCurrentTab] = useState(1);
    const [loading, setLoading] = useState(cid ? true : false)
    const [cachedGetGraphUserAndGroupByClient, setCachedGetGraphUserAndGroupByClient] = useState(null)
    // Need to save pageValues to customFields on ClientItem or OnboardingItem.
    const [pageValues, setPageValues] = useState(defaultPageValues)

    const [onboardingItem, setOnboardingItem] = useState({
        FranchiseeName: null,
        IntakeEmails: [],
        Status: null,
        Step: 1,
        StartDate: null,
        ImpEngProfileId: null,
        ClientId: null,
        SystemConfigurationCompleted: null,
    })


    const avgPage1 = useMemo(() => {
        const total = pageValues.page11 + pageValues.page12 + pageValues.page13 + pageValues.page14;
        return total / (total ? 4 : 1);
    }, [pageValues.page11, pageValues.page12, pageValues.page13, pageValues.page14]);

    const avgPage5 = useMemo(() => {
        let total = pageValues.page51 + pageValues.page52;
        return total / (total ? 2 : 1);
    }, [pageValues.page51, pageValues.page52]);

    const avgPage7 = useMemo(() => {
        const total = pageValues.page71 + pageValues.page72 + pageValues.page73 + pageValues.page74;
        return total / (total ? 4 : 1);
    }, [pageValues.page71, pageValues.page72, pageValues.page73, pageValues.page74]);


    useEffect(() => {
        setPageValues((prevValues) => {
            return {
                ...prevValues,
                page1: Number.isNaN(avgPage1) ? 0 : avgPage1,
                page5: Number.isNaN(avgPage5) ? 0 : avgPage5,
                page7: Number.isNaN(avgPage7) ? 0 : avgPage7,
            }
        });
    }, [avgPage1, avgPage5, avgPage7]);



    useEffect(() => {
        if (!cid) {
            clearFranchiseeState()
        }
        grabOrPopulateOnboardingStatus();
    }, [cid])


    useEffect(() => {
        return () => {
            coreState.clear()
        }
    }, [])

    const clearFranchiseeState = () => {
        setLoading(true)
        setCurrentStep(0)
        setCurrentTab(0)
        setClientItem(null)
        setPageValues(defaultPageValues)
        setOnboardingItem({
            FranchiseeName: null,
            IntakeEmails: [],
            Status: null,
            Step: 1,
            StepValues: defaultPageValues,
            StartDate: null,
            ImpEngProfileId: null,
            ClientId: null,
            SystemConfigurationCompleted: null,
        })
    }

    const grabOrPopulateOnboardingStatus = async () => {
        let onboardingResult = await onboardingMgr.search({ searchModel: 'x => (x.Status == 1 || x.Status == 2 || x.Status == 3 || x.Status == 4) && x.IsActive == true && x.IsDeleted == false' })

        setOnboardingActiveList(onboardingResult)
        if (cid) {
            let foundOnboarding = onboardingResult?.find(x => x.ClientId === cid)

            if (onboardingItem.ClientId === null && foundOnboarding) {
                setOnboardingItem(foundOnboarding)
                setCurrentStep(foundOnboarding.Step)
                if (foundOnboarding.StepValues && !_.isEmpty(foundOnboarding.StepValues)) {
                    setPageValues(foundOnboarding.StepValues)
                }
            }

            if (clientItem === null)
                await clientMgr.search({ searchModel: `x => x.ClientId == cid && x.IsActive == true && x.IsDeleted == false`, params: { cid: cid } }).then(r => {
                    setClientItem((r.find(x => x.ClientId === cid)))
                })
        }
        setLoading(false)
    }

    const updateOnboardingProcess = async (step, updatedPageValues, newClient = null) => {
        let updatedOnboardingItem = { ...onboardingItem }
        if (updatedPageValues) {
            updatedOnboardingItem.StepValues = updatedPageValues
        }

        if (step >= updatedOnboardingItem["Step"]) {
            switch (step) {
                case 1:
                    if (!updatedOnboardingItem['FranchiseeName']) {
                        updatedOnboardingItem["FranchiseeName"] = newClient?.Name;
                    }
                    updatedOnboardingItem["Status"] = 1; // FranchiseeSetup
                    break;
                case 2:
                case 3: // Back up.
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    updatedOnboardingItem["Status"] = 2; // InProgress
                    break;
                case 9:
                    updatedOnboardingItem["Status"] = 3; // Review
                    break;
                case 10:
                    updatedOnboardingItem["Status"] = 4; // ReviewComplete
                    break;
                case 11:
                    updatedOnboardingItem["Status"] = 5; // Live
                    break;
                default:
                    console.log(`Step ${step} is not handled explicitly`);
            }

            if (!updatedOnboardingItem.ImpEngProfileId) {
                updatedOnboardingItem.ImpEngProfileId = coreContext?._id
            }
            if (!updatedOnboardingItem.ClientId) {
                updatedOnboardingItem["ClientId"] = newClient?.ClientId
            }
            // Step must always be greater then the current step update the onboardingItem's Step field.
            // Steps larger than 8 are just saved to step 8. InReview, CompletedReview, etc.
            if (step > updatedOnboardingItem["Step"]) {
                if (step > 8) {
                    updatedOnboardingItem["Step"] = 8
                } else {
                    updatedOnboardingItem["Step"] = step
                }
            }
            if (!updatedOnboardingItem?.StartDate) {
                updatedOnboardingItem.StartDate = new Date();
            }
        }

        await onboardingMgr.saveItem(updatedOnboardingItem).then(r => {
            if (r.Success) {
                setOnboardingItem(r.Items.first())
            }
        })
    }

    const handleProgress = (filled, total, targetPage, callback) => {
        let progress = Math.floor((filled / total) * 100);

        setPageValues(prevValues => {
            const updatedValues = { ...prevValues, [targetPage]: progress };

            if (callback) callback(updatedValues);

            return updatedValues;
        });
    };


    return (
        <WizardDomainContext.Provider value={{
            updateOnboardingProcess,
            clientItem,
            setClientItem,
            pageValues,
            onboardingActiveList,
            currentStep,
            setCurrentStep,
            onboardingItem,
            setOnboardingItem,
            currentTab,
            setCurrentTab,
            loading,
            setLoading,
            handleProgress,
            clearFranchiseeState,
            cachedGetGraphUserAndGroupByClient,
            setCachedGetGraphUserAndGroupByClient
        }}>
            {children}
        </WizardDomainContext.Provider>

    );
}

export const useWizardDomainContext = () => useContext(WizardDomainContext);