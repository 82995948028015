import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import { FieldControl } from 'spark-core-dx/components';
import { useWizardDomainContext } from '../../WizardDomainProvider';
import { useValidationErrors, useGenericManager } from 'spark-core-dx/hooks';
import { useCoreStateContext } from 'spark-core-dx/contexts';
import _ from 'lodash';
import WizardFooter from './WizardFooter';
import { ItemServiceFactory } from '../../../../services/ItemServiceFactory';

const Logos = ({ setCurrentTab, themeItem, setThemeItem, setChanges }) => {
  const { cid } = useParams();
  const coreState = useCoreStateContext();
  const { clientItem, setClientItem, handleProgress, updateOnboardingProcess } = useWizardDomainContext();
  const itemService = new ItemServiceFactory()
  const { hasAndShowValidationErrors } = useValidationErrors();
  const clientMgr = useGenericManager(itemService.ItemServiceEnum.Client, itemService);
  const fileMgr = useGenericManager(itemService.ItemServiceEnum.File, itemService);
  const themeMgr = useGenericManager(itemService.ItemServiceEnum.Theme, itemService);

  const clientMetadata = useMemo(() => {
    return clientMgr?.uiMetadata?.first()
  }, [clientMgr?.uiMetadata])
  const themeMetadata = useMemo(() => {
    return themeMgr?.uiMetadata?.first()
  }, [themeMgr?.uiMetadata])

  const [horizLogoFile, setHorizLogoFile] = useState(clientItem?.HorizontalLogoFileId ?? null)
  const [themeInfo, setThemeInfo] = useState({
    LoadingSVG: themeItem?.LoadingSVG ?? null,
    HeaderImage: themeItem?.HeaderImage ?? null,
    IconFileId: themeItem?.IconFileId ?? null,
    FaviconFileId: themeItem?.FaviconFileId ?? null,
  })

  // Everything except horizLogoFile is in themeItem.. 
  const fieldsLogo = ["HeaderImage", "IconFileId", "FaviconFileId", "LoadingSVG"]

  useEffect(() => {
    // // Not required. Just fills based on done-ness.
    // if (themeMetadata?.Fields) {
    //   const calculateFields = (fields) => {
    //     const filledFields = fields
    //       .filter(field => themeInfo[field.FieldName] !== null && themeInfo[field.FieldName] !== '').length
    //       + (horizLogoFile ? 1 : 0)
    //     // .filter(field => field.IsRequired === true)

    //     const totalFields = fields.length + 1 // horizLogoFile
    //     // .filter(field => field.IsRequired === true)

    //     return { filledFields, totalFields };
    //   };

    //   let fields = themeMetadata?.Fields?.filter(field => fieldsLogo.includes(field.FieldName))

    //   const { filledFields: filledFields, totalFields: totalFields } = calculateFields(fields);

    //   handleProgress(filledFields, totalFields, 'page12');
    // }

    const filesToLoad = [horizLogoFile, themeInfo?.FaviconFileId, themeInfo?.HeaderImage, themeInfo?.IconFileId];
    filesToLoad.forEach(fileId => {
      if (fileId) {
        var formFileUrls = coreState.get('FormFilesUrls');
        const foundFile = _.find(formFileUrls, ffu => ffu.FileId === fileId)
        if (!foundFile) {
          fileMgr.GetFileUrl(fileId, true).then(r => {
            coreState.merge([r.Items.first()], 'FormFilesUrls')
          })
          // fileMgr.RunOperation({ operationName: "LoadFileUrl", id: fileId, isWarning: true }).then(r => {
          //   if (r.Success && r.Items.length > 0) {
          //     coreState.merge([r.Items.first()], 'FormFilesUrls')
          //   }
          // });
        }
      }
    });

  }, [themeMetadata?.Fields, themeInfo, horizLogoFile])

  useEffect(() => {
    const checkForChanges = () => {
      const isThemeChanged = Object.keys(themeInfo).some(key => themeInfo[key] !== themeItem[key]);
      const isLogoFileChanged = horizLogoFile !== clientItem?.HorizontalLogoFileId;

      if (isThemeChanged || isLogoFileChanged) {
        setChanges(true);
      } else {
        setChanges(false);
      }
    };
    if (themeItem) {
      checkForChanges();
    }
  }, [themeInfo, horizLogoFile, clientItem, themeItem]);


  const handleOnChange = (value, fieldName) => {
    if (fieldName === 'HorizontalLogoFileId') {
      setHorizLogoFile(value)
    } else {
      setThemeInfo(prev => ({
        ...prev,
        [fieldName]: value,
      }));
    }
  }

  // Fields: Horiz, Vert, Icon, Favicon, Loading SVG
  // Need to go into Spark and update the FileControl.
  const handleOnSave = async (isContinue) => {
    if (!hasAndShowValidationErrors("logos")) {
      let copiedTheme;
      if (themeItem) {
        copiedTheme = {
          ...themeItem,
          Name: `${clientItem.Name}'s Theme`
        }
        _.forEach(themeInfo, (x, key) => {
          copiedTheme[key] = x
        })
      } else {
        copiedTheme = {
          ...themeInfo,
          ClientId: cid,
          Name: `${clientItem.Name}'s Theme`,
          DocumentTitle: `${clientItem.Name}`
        }
      }
      await themeMgr.saveItem(copiedTheme).then(r => {
        if (r.Success) {
          let newTheme = r.Items.first()
          setThemeItem(newTheme)
        }
      })

      if (clientItem) {
        let copiedClient;
        copiedClient = { ...clientItem, HorizontalLogoFileId: horizLogoFile }

        await clientMgr.saveItem(copiedClient).then(r => {
          if (r.Success) {
            let newClient = r.Items.first()
            setClientItem(newClient)
          }
        })
      }
      await handleProgress(1, 1, 'page12', updatedPageValues => {
        updateOnboardingProcess(1, updatedPageValues);
      });

      if (isContinue) {
        setCurrentTab(3)
      }
    }
  }

  const renderLogos = () => {
    return <>
      <FieldControl key={1} groupId={'logos'} onChange={handleOnChange} value={horizLogoFile} fieldMetadata={clientMetadata?.Fields.find((x) => x.FieldName === "HorizontalLogoFileId")} />
      {fieldsLogo.map((field) => {
        const fieldMetadata = themeMetadata?.Fields?.find(x => x.FieldName === field)
        if (fieldMetadata) {
          return (<FieldControl key={field} groupId={'logos'} onChange={handleOnChange} value={themeInfo[field]} fieldMetadata={fieldMetadata} />
          )
        }
      })}
    </>


  }

  return (
    <>
      <div className="wizard-content">
        <div className=" branding-logos">
          {renderLogos()}
        </div>

      </div>
      <WizardFooter
        showPrevBtn={true}
        prevBtnOnClick={() => setCurrentTab(1)}
        showSecBtn={true}
        primaryBtnOnClick={async () => await handleOnSave(true)}
        secBtnOnClick={async () => await handleOnSave()}
        primaryBtnText={"Save & Continue"}
        secBtnText={"Save"} />

    </>
  )
}

export default Logos