import React, { useEffect, useMemo, useState } from 'react'
import { FieldControl, Loading } from 'spark-core-dx/components';
import { useValidationErrors, useGenericManager } from 'spark-core-dx/hooks';
import { useWizardDomainContext } from '../../WizardDomainProvider';
import WizardFooter from './WizardFooter';
import _ from 'lodash';
import { ItemServiceFactory } from '../../../../services/ItemServiceFactory';
import { useNavigate, useParams } from 'react-router-dom';

const CompanyInformation = ({ setCurrentTab, setChanges }) => {
  const { clientItem, setClientItem, handleProgress, onboardingItem, setOnboardingItem, currentStep, setCurrentStep, updateOnboardingProcess } = useWizardDomainContext();
  const { hasAndShowValidationErrors } = useValidationErrors();
  const navigate = useNavigate();
  const itemService = new ItemServiceFactory()
  const clientMgr = useGenericManager(itemService.ItemServiceEnum.Client, itemService);
  const clientMetadata = useMemo(() => {
    return clientMgr?.uiMetadata?.first()
  }, [clientMgr?.uiMetadata])

  const [isLoading, setIsLoading] = useState(true)

  // FirstName will always come with LastName. 
  // PrimaryPhone will always come with SecondaryPhone.
  const fieldsCompInfo = ["Name", "DisplayName", "ShortName", "FirstName", "Email", "PrimaryPhone"]
  const fieldsAdvancedCompInfo = ["Name", "DisplayName", "ShortName", "EIN", "FirstName", "Email", "PrimaryPhone", "PrimaryAddress"]

  useEffect(() => {
    if (clientMgr?.uiMetadata) {
      setIsLoading(false)
    }
  }, [clientMgr?.uiMetadata])

  const [localClientItem, setLocalClientItem] = useState({
    Name: clientItem?.Name,
    DisplayName: clientItem?.DisplayName,
    ShortName: clientItem?.ShortName,
    EIN: clientItem?.EIN,
    FirstName: clientItem?.FirstName,
    LastName: clientItem?.LastName,
    PrimaryPhone: clientItem?.PrimaryPhone,
    SecondaryPhone: clientItem?.SecondaryPhone,
    Email: clientItem?.Email,
    PrimaryAddress: clientItem?.PrimaryAddress,
  })

  const [copyOfLocalClientItem, setCopyOfLocalClientItem] = useState({
    Name: clientItem?.Name,
    DisplayName: clientItem?.DisplayName,
    ShortName: clientItem?.ShortName,
    EIN: clientItem?.EIN,
    FirstName: clientItem?.FirstName,
    LastName: clientItem?.LastName,
    PrimaryPhone: clientItem?.PrimaryPhone,
    SecondaryPhone: clientItem?.SecondaryPhone,
    Email: clientItem?.Email,
    PrimaryAddress: clientItem?.PrimaryAddress,
  })

  useEffect(() => {
    if (!isLoading) {
      if (setChanges) {
        setChanges(hasChanges())
      }
    }
  }, [localClientItem, copyOfLocalClientItem])

  const handleProgressUpdate = () => {
    const calculateFields = (fields) => {
      const filledFields = fields
        .filter(field => field.IsRequired === true)
        .filter(field => localClientItem[field.FieldName])?.length
      // + (onboardingItem.IntakeEmails.length > 0 ? 1 : 0);
      // Intake Emails are not required for the Pilot phase.

      const totalFields = fields
        .filter(field => field.IsRequired === true).length
      // + 1 // Intake Emails

      return { filledFields, totalFields };
    };

    let fieldsCI = clientMetadata?.Fields?.filter(field => fieldsCompInfo.includes(field.FieldName))
    let fieldsACI = clientMetadata?.Fields?.filter(field => fieldsAdvancedCompInfo.includes(field.FieldName))

    if (currentStep === 1) {
      const { filledFields, totalFields } = calculateFields(fieldsCI);
      return { filledFields, totalFields };
    } else {
      const { filledFields, totalFields } = calculateFields(fieldsACI);
      return { filledFields, totalFields };
    }
  }

  const handleOnChange = (value, fieldName) => {
    setLocalClientItem(prev => {
      return { ...prev, [fieldName]: value }
    })
  }

  const hasChanges = () => {
    const clientChanged = JSON.stringify(localClientItem) !== JSON.stringify(copyOfLocalClientItem);

    return clientChanged
  };

  const handleOnSave = async (isContinue) => {
    if (!hasAndShowValidationErrors(currentStep === 1 ? "compInfo" : "advCompInfo")) {
      let copiedClient;
      if (clientItem) {
        copiedClient = { ...clientItem }


        _.forEach(localClientItem, (x, key) => {
          //If shortname exists and its different, we need to update the clientDomain.
          copiedClient[key] = x
        })

      } else {
        copiedClient = { ...localClientItem }
      }

      const { filledFields: filled, totalFields: total } = handleProgressUpdate()

      //We are saving the shortname right here too.

      await clientMgr.saveItem(copiedClient).then(async (r) => {
        if (r.Success) {
          let newClient = r.Items.first()
          navigate(`/wizard/${newClient._id}`)
          setClientItem(newClient)
          setCopyOfLocalClientItem(localClientItem)

          const processStep = async (targetStep, progressPage, newClient = null) => {
            await handleProgress(filled, total, progressPage, updatedPageValues => {
              updateOnboardingProcess(targetStep, updatedPageValues, newClient);
            });
          };

          if (isContinue) {
            if (currentStep === 1) {
              setCurrentTab(2);
              await processStep(currentStep, 'page11', newClient);
            } else if (currentStep === 2) {
              setCurrentStep(currentStep + 1);
              await processStep(currentStep + 1, 'page2');
            }
          } else {
            if (currentStep === 1) {
              await processStep(currentStep, 'page11', newClient);
            } else if (currentStep === 2) {
              await processStep(currentStep, 'page2');
            }
          }
        }
      })

    }
  }

  const renderCompInfoFields = (fieldList, groupId) => {
    return (
      <div className='fields-container'>
        {fieldList.map((field) => {
          switch (field) {
            case 'ShortName': {
              return <FieldControl key={field} disabled={onboardingItem.SystemConfigurationCompleted !== null} groupId={groupId} onChange={handleOnChange} value={localClientItem[field]} fieldMetadata={clientMetadata?.Fields.find((x) => x.FieldName === field)} />
            }
            case 'FirstName': {
              return (
                <div className='grouped-fields' key={`${groupId}-name`}>
                  <FieldControl key={field} groupId={groupId} onChange={handleOnChange} value={localClientItem[field]} fieldMetadata={clientMetadata?.Fields.find((x) => x.FieldName === field)} />
                  <FieldControl key={'LastName'} groupId={groupId} onChange={handleOnChange} value={localClientItem['LastName']} fieldMetadata={clientMetadata?.Fields.find((x) => x.FieldName === 'LastName')} />
                </div>
              )
            }
            case 'PrimaryPhone': {
              return (
                <div className='grouped-fields' key={`${groupId}-phone`}>
                  <FieldControl key={field} groupId={groupId} onChange={handleOnChange} value={localClientItem[field]} fieldMetadata={clientMetadata?.Fields.find((x) => x.FieldName === field)} />
                  <FieldControl key={'SecondaryPhone'} groupId={groupId} onChange={handleOnChange} value={localClientItem['SecondaryPhone']} fieldMetadata={clientMetadata?.Fields.find((x) => x.FieldName === 'SecondaryPhone')} />
                </div>
              )
            }
            default: {
              return <FieldControl key={field} groupId={groupId} onChange={handleOnChange} value={localClientItem[field]} fieldMetadata={clientMetadata?.Fields.find((x) => x.FieldName === field)} />
            }
          }
        })}
        {
          currentStep === 1 ?
            <FieldControl
              key={'intakeEmails'}
              groupId={'compInfo'}
              fieldMetadata={{
                FieldType: 15,
                FieldName: 'IntakeEmails',
                DisplayName: 'Intake Manager Emails',
                ValidationRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                ValidationRegexMessage: 'Please enter a valid email address.',
                Description: 'Emails will be sent to these users to continue the intake process.'
              }}
              value={onboardingItem.IntakeEmails}
              onChange={handleIntakeEmails}
            />
            : ''}
      </div>
    )

  }

  const handleIntakeEmails = (value) => {
    setOnboardingItem(prev => {
      return { ...prev, IntakeEmails: value }
    })
  }

  return (
    <>
      <div className="wizard-content">
        {!isLoading ? clientMetadata && currentStep === 1 ? renderCompInfoFields(fieldsCompInfo, 'compInfo') : renderCompInfoFields(fieldsAdvancedCompInfo, 'advCompInfo') : <Loading type={'spinner-2'} />}
      </div>
      <WizardFooter
        showPrevBtn={currentStep === 2 ? true : false}
        prevBtnOnClick={() => setCurrentStep(currentStep - 1)}
        showSecBtn={true}
        primaryBtnOnClick={async () => { await handleOnSave(true) }}
        secBtnOnClick={async () => await handleOnSave()}
        primaryBtnText={"Save & Continue"}
        secBtnText={"Save"} />
    </>

  )
}

export default CompanyInformation