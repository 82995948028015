import React, { useEffect, useRef, useState } from 'react';
import './home.scss'
import { useCoreRoute, useGenericManager } from 'spark-core-dx/hooks';
import { useCoreContext, useAuthContext } from 'spark-core-dx/contexts';
import { FieldControl, IconControl, LoadingSkeleton, ButtonControl } from 'spark-core-dx/components'
import workers from '../../../content/homeImages/workers.png'
import map from '../../../content/homeImages/map.png'
import { ItemServiceFactory } from '../../../services/ItemServiceFactory';
import _ from 'lodash';

const Home = () => {
    const coreContext = useCoreContext();
    const { isAuthorized } = useAuthContext();
    const { coreNavigate } = useCoreRoute();
    const franchisesRef = useRef()
    const [search, setSearch] = useState('')
    const itemService = new ItemServiceFactory()
    const profileMgr = useGenericManager(itemService.ItemServiceEnum.Profile)
    const [franchisees, setFranchisees] = useState(null)

    useEffect(() => {
        profileMgr.RunOperation({ operationName: "Homepage" }).then(r => {
            if (r.Success) {
                const franchiseeList = r.Items
                // Sorting the list of franchisees by DisplayName, whilst ignoring the word "the"
                const removeLeadingThe = (str) => str.replace(/^the\s+/i, '').toLowerCase();
                setFranchisees(_.orderBy(franchiseeList, [(item) => removeLeadingThe(item.DisplayName)], ['asc']))
            }
        })
    }, [])

    useEffect(() => {
        if (isAuthorized) {
            // console.log(_.sortBy(coreContext?.ProfileRoles ?? [], x => x.Sequence)?.first()?.RedirectScreenKey)
            coreNavigate(coreContext.RedirectScreenKey)
        }
    }, [isAuthorized])

    const franchiseeRedirect = (franchisee) => {
        window.location.href = franchisee.RedirectURL
    }

    return (
        <div className='homepage'>
            <section id="landing">
                <div className="left-column">
                    {coreContext?.Launch &&
                        <div className="left-wrapper">
                            <h1 className="homepage-title">Welcome to</h1>
                            <h1 className="homepage-title bold">{coreContext?.Launch?.IsDefaultClient ? "ABMFG" : coreContext?.Launch?.ClientName} Portal</h1>

                            <div className="btn-wrapper">
                                <ButtonControl className="login-btn" loading={coreContext?.Profile === null} onClick={() => coreNavigate('/login/')}>Login</ButtonControl>
                                {coreContext?.Launch?.IsDefaultClient && <div className="search-portal">or <span onClick={() => {
                                    franchisesRef.current.scrollIntoView({ behavior: 'smooth' })
                                }}>search for your franchisee portal</span></div>}
                            </div>
                        </div>}
                </div>
                <div className="right-column">
                    <div className="img-wrapper left">
                        <div className="img"></div>

                        <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <filter id="flt_tag">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                    <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <div className="img-wrapper middle">
                        <div className="img"></div>

                        <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <filter id="flt_tag">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                    <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <div className="img-wrapper right">
                        <div className="img"></div>

                        <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <filter id="flt_tag">
                                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="flt_tag" />
                                    <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className="background-fade"></div>
            </section>
            {coreContext?.Launch?.IsDefaultClient &&
                <>
                    <section id="about">
                        <div className="top-half">
                            <div className="img-wrapper">
                                <img className="about-img" src={workers} alt="" />
                            </div>
                            <div className="paragraph-wrapper">
                                <p>
                                    ABM Franchising Group, a key division of ABM, consists of a wide network of franchises providing top-notch mechanical and electrical services and preventive maintenance solutions. They serve diverse markets, including commercial office buildings, educational institutions, municipalities, hospitals, data centers, and industrial facilities.
                                </p>
                                <p>
                                    Their comprehensive services ensure the efficient functioning of various infrastructures, from maintaining safety in educational and medical environments to ensuring uninterrupted operations in data centers and industrial facilities. By addressing immediate needs and implementing strategic preventive measures, ABM Franchising Group helps clients reduce costs, minimize downtime, and ensure long-term system reliability.
                                </p>
                            </div>
                        </div>

                        <div className="bottom-half">
                            <div className="paragraph-wrapper">
                                <p>
                                    We bring solutions to clients in over 10 countries through our extensive international franchise network, comprising more than 200 specialized mechanical and electrical contractors. Each franchise within our network upholds the high standards and quality of service that define ABM. Thoroughly vetted and trained, our contractors deliver exceptional mechanical and electrical solutions, ensuring clients receive consistent expertise and professionalism, regardless of location. This commitment to excellence is a cornerstone of our operations, reflected in the satisfaction and trust of our diverse client base.
                                </p>
                                <p>
                                    By leveraging our vast franchise network, we enhance the client experience by offering localized, responsive service backed by the robust support and resources of ABM. This combination of global capability and local presence allows us to deliver tailored solutions that meet the specific needs of each client, maintaining the reliability and performance they have come to expect from ABM.
                                </p>
                            </div>
                            <div className="img-wrapper">
                                <img className="about-img" src={map} alt="" />
                            </div>

                        </div>
                    </section>

                    <section id="franchises" ref={franchisesRef}>
                        <div className="search-wrapper">
                            <IconControl className={'search-icon'} iconKey={'search'} />
                            <FieldControl
                                fieldMetadata={{
                                    FieldType: 1,
                                    FieldName: 'Franchise Name',
                                    PlaceHolder: 'Search'
                                }}
                                className='search'
                                value={search}
                                onChange={value => setSearch(value)}
                            />
                        </div>
                        <div className="franchisee-img-container">
                            {
                                franchisees ?
                                    <>
                                        {franchisees?.filter(x => search ? x.DisplayName?.toLowerCase().includes(search.toLowerCase()) : true)?.map((x, i) => <img className="img" key={i} src={x.Image?.FileUrl} alt="" onClick={() => franchiseeRedirect(x)} />)}
                                        {!search && Array.from({ length: 30 - franchisees?.length }, (_, i) => <div key={i} className="placeholder" />)}
                                    </>
                                    :
                                    <>
                                        {Array.from({ length: 30 }, (_, i) => <LoadingSkeleton key={i} className="" width={'16rem'} height={'8rem'} borderRadius={".5rem"} />)}
                                    </>
                            }
                        </div>
                    </section>
                </>
            }
        </div>
    );
};

export default Home;