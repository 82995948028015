import React from 'react'
import './appCard.scss'

const AppCard = ({ disabled, src, appName, url }) => {
  // Need to add the redirect for the links.
  if (src === 'empty') {
    return (
      <div className={`app-card disabled`}>
        <div className='app-icon' style={{ backgroundColor: 'gray' }} draggable="false" />
        <p></p>
      </div>
    )
  } else
    return (
      <div className={`app-card${disabled ? " disabled" : ""}`} >
        <a href={url} target={"_blank"}>
          <img className='app-icon' src={src} draggable="false" />
          <p>{appName}</p>
        </a>
      </div>
    )
}

export default AppCard