import React, { useState, useRef, useEffect } from 'react'
import { ButtonControl, IconControl } from 'spark-core-dx/components';
import './accordion.scss'

const Accordion = ({
  title,
  children,
  opened,
  btnOnClick,
  btnText,
}) => {
  const [open, setOpen] = useState(opened || true)
  const contentRef = useRef(null);

  useEffect(() => {
    if (opened) {
      setOpen(opened)
    }
  }, [opened])

  return (
    <div className='accordion-container'>
      <div className='accordion-header' onClick={() => setOpen(!open)}>
        <h3>{title}</h3>
        <div className='accordion-button-container'>
          {btnOnClick && <ButtonControl minWidth={'8rem'} value={btnText} type={'okay'} onClick={async (e) => { e.stopPropagation(); await btnOnClick(); }} />}
          <IconControl className={open ? 'open' : ''} iconKey={'angledown'} width={'1.5rem'} height={'1.5rem'} />
        </div>
      </div>
      <div className='accordion-fields-container'
        style={{ maxHeight: open ? `100%` : '0px' }}
        ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default Accordion