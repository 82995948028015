import React, { useEffect, useState } from 'react'
import WizardFooter from './WizardFooter'
import { Loading } from 'spark-core-dx/components'
import { useWizardDomainContext } from '../../WizardDomainProvider';
import { useValidationErrors } from 'spark-core-dx/hooks'

const Application = ({ applicationList, setCurrentTab, setChanges }) => {
    const { handleProgress, currentStep, updateOnboardingProcess } = useWizardDomainContext();
    const { hasAndShowValidationErrors, getValidationErrors } = useValidationErrors();

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        // Set conditional for loading here.
        setIsLoading(false)
    }, [])

    const handleSave = async (isContinue) => {
        if (!hasAndShowValidationErrors('')) {
            if (isContinue) {
                setCurrentTab(3)
            }
            await handleProgress(1, 1, 'page72', updatedPageValues => {
                updateOnboardingProcess(currentStep, updatedPageValues);
            });
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <div className="wizard-content">
                {
                    !isLoading ? 'Coming soon..' : <Loading type={'spinner-2'} />
                }
            </div>
            <WizardFooter
                disableAll={isLoading}
                showPrevBtn={true}
                prevBtnOnClick={() => setCurrentTab(1)}
                // showSecBtn={true}
                primaryBtnOnClick={async () => await handleSave(true)}
                secBtnOnClick={async () => await handleSave()}
                primaryBtnText={"Continue"}
                secBtnText={"Save"} />
        </>
    )
}

export default Application