import React, { useState, useEffect } from 'react'
import _ from "lodash"
import WizardFooter from './WizardFooter'
import { FieldControl, Loading } from 'spark-core-dx/components';
import { useValidationErrors, useGenericManager } from 'spark-core-dx/hooks';
import { useWizardDomainContext } from '../../WizardDomainProvider';
import { ItemServiceFactory } from '../../../../services/ItemServiceFactory';
import BuildOpsSetup from '../systemApplicationSetup/BuildOpsSetup';
import SageSetup from '../systemApplicationSetup/SageSetup';

const SystemOfRecords = ({ setCurrentTab, applicationList, config, setConfig, setChanges }) => {
    const itemService = new ItemServiceFactory()
    const configMgr = useGenericManager(itemService.ItemServiceEnum.Config, itemService);
    const { handleProgress, currentStep, setCurrentStep, clientItem, updateOnboardingProcess } = useWizardDomainContext();
    const { hasAndShowValidationErrors, getValidationErrors } = useValidationErrors();
    const franchiseeExternalAppSettingMgr = useGenericManager(itemService.ItemServiceEnum.FranchiseeExternalAppSetting, itemService);
    const [buildOpsData, setBuildOpsData] = useState({})
    const [sageData, setSageData] = useState({})

    const [copiedBOData, setCopiedBOData] = useState({})
    const [copiedSageData, setCopiedSageData] = useState({})

    const [errorApps, setErrorApps] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (config?.BuildOpsConnectionString && _.isEmpty(buildOpsData)) {
            const entries = config?.BuildOpsConnectionString.split(';').map((entry) => {
                const [key, value] = entry.split('=');
                return [key, value];
            });
            setBuildOpsData(Object.fromEntries(entries))
            setCopiedBOData(Object.fromEntries(entries))
        }
        if (config?.SageConnectionString && _.isEmpty(sageData)) {
            const entries = config?.SageConnectionString.split(';').map((entry) => {
                const [key, value] = entry.split('=');
                return [key, value];
            });
            setSageData(Object.fromEntries(entries))
            setCopiedSageData(Object.fromEntries(entries))
        }
    }, [config])

    useEffect(() => {
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (!isLoading) {
            setChanges(hasChanges())
        }
    }, [buildOpsData, sageData, copiedBOData, copiedSageData])

    const hasChanges = () => {
        const boChanged = JSON.stringify(buildOpsData) !== JSON.stringify(copiedBOData);
        const sageChanged = JSON.stringify(sageData) !== JSON.stringify(copiedSageData);

        return boChanged || sageChanged
    };

    const handleProgressUpdate = () => {
        let filledFields = 0;
        let totalFields = 0;

        // Each application has it's own requirements.

        Object.keys(applicationList)?.forEach((app) => {
            switch (app) {
                case 'BuildOps':
                    if (_.every(buildOpsData, (key, value) => value)) {
                        filledFields++;
                    }
                    totalFields++;
                    break;
                case 'Sage':
                    if (_.every(sageData, (key, value) => value)) {
                        filledFields++;
                    }
                    totalFields++;
                    break;
            }
        })
        return { filledFields, totalFields }
        // handleProgress(filledFields, totalFields, 'page71');
    }

    const returnApplications = (appName) => {
        switch (appName) {
            case "BuildOps":
                return <BuildOpsSetup key={appName} data={buildOpsData} appName={appName} errorApps={errorApps} updateData={(v, fn) => {
                    const copiedBuildOpsData = { ...buildOpsData }
                    copiedBuildOpsData[fn] = v
                    setBuildOpsData(copiedBuildOpsData)
                }} />
            case "Sage":
                return <SageSetup key={appName} data={sageData} appName={appName} errorApps={errorApps} updateData={(v, fn) => {
                    const copiedSageData = { ...sageData }
                    copiedSageData[fn] = v
                    setSageData(copiedSageData)
                }} />
        }
    }

    const handleSave = async (isContinue) => {
        if (!hasAndShowValidationErrors('application')) {
            if (applicationList['Sage'] && !applicationList['Sage'].URL) {
                applicationList['Sage'].URL = "https://www.intacct.com/ia/acct/login.phtml"
                await franchiseeExternalAppSettingMgr.saveItem(applicationList['Sage'])
            }
            if (applicationList['BuildOps'] && !applicationList['Sage'].BuildOps) {
                applicationList['BuildOps'].URL = "https://login.live.buildops.com/"
                await franchiseeExternalAppSettingMgr.saveItem(applicationList['BuildOps'])
            }
            const copiedConfig = { ...config }
            copiedConfig.BuildOpsConnectionString = `clientId=${buildOpsData.clientId};url=${buildOpsData.url};clientSecret=${buildOpsData.clientSecret};tenantId=${buildOpsData.tenantId};version=${buildOpsData.version}`
            copiedConfig.SageConnectionString = `companyId=${sageData.companyId};senderId=${sageData.senderId};senderPassword=${sageData.senderPassword};userId=${sageData.userId};userPassword=${sageData.userPassword}`
            await configMgr.saveItem(copiedConfig).then(r => {
                if (r.Success) {
                    setConfig(r.Items.first())
                    if (applicationList['BuildOps']) {
                        setCopiedBOData(buildOpsData)
                    }
                    if (applicationList['Sage']) {
                        setCopiedSageData(sageData)
                    }
                }
            })
            const { filledFields, totalFields } = handleProgressUpdate();
            if (isContinue) {
                setCurrentTab(2)
            }
            await handleProgress(filledFields, totalFields, 'page71', updatedPageValues => {
                updateOnboardingProcess(currentStep, updatedPageValues);
            });
            return true;
        } else {
            const listOfErrors = getValidationErrors('application')
            const errorList = Object.keys(listOfErrors).filter(x => x !== "__updatedDate").map(y => { return y.split('-')[0] })
            setErrorApps([...new Set(errorList)])
            return false;
        }
    }
    return (
        <>
            <div className='wizard-content'>
                {
                    applicationList && Object.keys(applicationList)?.length > 0
                        ? Object.keys(applicationList)?.map(appName => {
                            return returnApplications(appName)
                        })
                        : isLoading ?
                            <Loading type={'spinner-2'} />
                            : <p>No applications selected.</p>
                }
            </div>
            <WizardFooter
                disableAll={isLoading}
                showPrevBtn={true}
                prevBtnOnClick={() => setCurrentStep(currentStep - 1)}
                showSecBtn={true}
                primaryBtnOnClick={async () => await handleSave(true)}
                secBtnOnClick={async () => await handleSave()}
                primaryBtnText={"Save & Continue"}
                secBtnText={"Save"} />
        </>
    )
}

export default SystemOfRecords