import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import './entityObjectFieldMapping.scss'
import { useConvokeDomainContext } from '../ConvokeDomainProvider';
import { SystemTypes } from 'spark-core-dx/services';
import _ from 'lodash'
import { useCoreRoute, useGenericManager } from 'spark-core-dx/hooks'
import { useCoreContext } from 'spark-core-dx/contexts'
import { FieldControl, ButtonControl, Loading } from 'spark-core-dx/components'
import { ItemServiceFactory } from '../../../services/ItemServiceFactory'


const EntityObjectFieldMapping = ({ }) => {
    const itemService = new ItemServiceFactory()
    const { coreNavigate } = useCoreRoute();
    const coreContext = useCoreContext();
    const { eoId } = useParams();
    const entityObjectFieldMapMgr = useGenericManager(itemService.ItemServiceEnum.EntityObjectFieldMap)
    const entityObjectMgr = useGenericManager(itemService.ItemServiceEnum.EntityObject)
    const sourceObjectMgr = useGenericManager(itemService.ItemServiceEnum.SourceObject)
    const [dataDTI, setDataDTI] = useState(null)
    const [fieldMaps, setFieldMaps] = useState(null);
    const [selectedEntityObjectField, setSelectedEntityObjectField] = useState(null);
    const [loadingSourceObject, setLoadingSourceObject] = useState(false)
    const [search, setSearch] = useState({})


    const sourceObjectSelectData = useMemo(() => {
        return dataDTI?.AllSourceObjects.map(x => {
            return {
                ...x,
                Disabled: dataDTI?.SourceObjects?.find(y => y.SourceObjectId === x.Value) ? true : false
            }
        })
    }, [dataDTI, loadingSourceObject])


    useEffect(() => {
        const redirectData = sessionStorage.getItem("InternalRedirectData");
        if (redirectData) {
            const redirectDataParsed = JSON.parse(redirectData).first();
            setDataDTI(redirectDataParsed)
            setFieldMaps(redirectDataParsed.FieldMaps)
            sessionStorage.removeItem("InternalRedirectData");
        } else {
            entityObjectMgr.RunOperation({ operationName: "EntityObjectFieldMapping", id: eoId }).then(r => {
                setDataDTI(r.Items.first())
                setFieldMaps(r.Items.first().FieldMaps)
            });
        }
    }, [])

    const transformSourceObjectFieldToFieldMap = (sourceObjectField) => {
        return {
            Importance: 0,
            SourceObjectFieldId: sourceObjectField.SourceObjectFieldId,
            SourceObjectId: sourceObjectField.SourceObjectId,
            IsNew: true,
            RequiredAutoFinalize: false,
            InverseImportance: 255,
            IsActive: true,
            IsDeleted: false,
            OwnerProfileId: coreContext.Profile.UniqueId

        }

    }
    const onSelectSourceObjectField = (sourceObjectField) => {
        const copiedItem = _.cloneDeep(selectedEntityObjectField);
        const copiedFieldMaps = _.cloneDeep(fieldMaps)
        const parentFieldMapIndex = copiedFieldMaps.findIndex(x => x.UniqueId === selectedEntityObjectField.UniqueId);

        const fieldMapIndex = copiedItem.FieldMap.findIndex(x => x.SourceObjectId === sourceObjectField.SourceObjectId)

        if (fieldMapIndex === -1) {
            copiedItem.FieldMap.push(transformSourceObjectFieldToFieldMap(sourceObjectField));
        } else {
            copiedItem.FieldMap.splice(fieldMapIndex, 1, transformSourceObjectFieldToFieldMap(sourceObjectField))
        }
        setSelectedEntityObjectField(copiedItem)

        copiedFieldMaps.splice(parentFieldMapIndex, 1, copiedItem)
        setFieldMaps(copiedFieldMaps)

    }
    const updateFieldMap = (value, fieldName, fieldMap) => {
        //This is for the actual FieldMapItem
        const copiedItem = _.cloneDeep(fieldMaps);
        const fieldMapToUpdate = copiedItem.find(x => x.UniqueId === fieldMap.UniqueId)
        const fieldMapToUpdateIndex = copiedItem.findIndex(x => x.UniqueId === fieldMap.UniqueId)
        fieldMapToUpdate[fieldName] = value
        copiedItem.splice(fieldMapToUpdateIndex, 1, fieldMapToUpdate)
        setFieldMaps(copiedItem)
    }
    const updateSourceObjectsOnFieldMap = (value, fieldName, sourceObjectFieldMap) => {
        //This is for the list of Source Object Mapping inside of FieldMapItem
        const copiedItem = _.cloneDeep(fieldMaps);
        const fieldMapToUpdateIndex = copiedItem.findIndex(x => x.UniqueId === selectedEntityObjectField.UniqueId)
        const sourceObjectMappingIndex = copiedItem[fieldMapToUpdateIndex].FieldMap.findIndex(x => x.SourceObjectFieldId === sourceObjectFieldMap.SourceObjectFieldId)
        copiedItem[fieldMapToUpdateIndex].FieldMap[sourceObjectMappingIndex][fieldName] = value
        setFieldMaps(copiedItem);
        setSelectedEntityObjectField(copiedItem[fieldMapToUpdateIndex])
    }
    return (
        <div className={"entity-object-field-map"}>

            {dataDTI ? <>
                <div className="header-row">
                    <h2>Entity Object Field Mapping</h2>
                    <div className='back-link' onClick={() => { coreNavigate("/dynamic/entityobject/") }}>{"<<"} Back to Entity Object List</div>
                </div>
                <div className="eofm">
                    <div className="eofm-column">
                        <div className="column-header">
                            {dataDTI?.EntityObject?.DisplayName} Fields
                        </div>
                        <div className="field-container vertical-scrollable">
                            {fieldMaps?.map((x, i) => {
                                return <div key={i} className={"field" + (x.EntityObjectFieldId === selectedEntityObjectField?.EntityObjectFieldId ? " active" : "")}
                                    onClick={() => {
                                        setSelectedEntityObjectField(x)
                                    }}>
                                    {dataDTI?.EntityObject?.Fields.find(eof => eof.EntityObjectFieldId === x.EntityObjectFieldId)?.DisplayName}
                                    <span className={'fieldmap-count' + (x.FieldMap?.length > 0 ? " fieldmap-count-mapped" : "")}>{x.FieldMap?.length}</span>
                                    <input className={"importance"} type="number"
                                        min={"1"}
                                        max={'255'}
                                        value={x.Importance}
                                        onChange={(e) => {
                                            if (e.target.value <= 255)
                                                updateFieldMap(parseInt(e.target.value), "Importance", x)
                                        }}
                                    />
                                    <div className='checkbox-wrapper' title={'Use for Master Record'}>
                                        <FieldControl
                                            fieldMetadata={{
                                                FieldType: SystemTypes.UIFieldType.CheckBox,
                                                FieldName: "checkbox",
                                            }}
                                            className={"checkbox"}
                                            disableError={true}
                                            onChange={(value) => { updateFieldMap(value, "UseForMapping", x) }}
                                            value={x.UseForMapping}
                                        />
                                    </div>
                                </div>
                            })}
                        </div>

                    </div>

                    <div className={"sobj-column-container"}>
                        {dataDTI?.SourceObjects.map((sourceObject, i) => {
                            //We find the FieldMap Field that coorelates with the source object on the FieldMapItem.
                            const soOnfieldMap = selectedEntityObjectField?.FieldMap.find(fieldMap => fieldMap.SourceObjectId === sourceObject.SourceObjectId)
                            //Then we find the SourceObjectField, because FieldMap does not have the SourceObjectField's Name on it.
                            const selectedSourceObjectField = sourceObject?.Fields.find(soField => soOnfieldMap?.SourceObjectFieldId === soField.SourceObjectFieldId);
                            return <div key={i} className={"sobj-column" + (!selectedEntityObjectField ? " disabled" : "")}>
                                <div className="column-header">
                                    <span title={sourceObject.DisplayName}> {sourceObject.DisplayName}</span>
                                    <div className="sub-title-wrapper">
                                        <span className='sub-title'>{selectedSourceObjectField?.DisplayName}</span>
                                        {selectedSourceObjectField && <>
                                            <input className={"importance"} type="number"
                                                min={"1"}
                                                max={'255'}
                                                value={soOnfieldMap.Importance}
                                                onChange={(e) => {
                                                    if (e.target.value <= 255) {
                                                        updateSourceObjectsOnFieldMap(parseInt(e.target.value), "Importance", soOnfieldMap)
                                                    }
                                                }}
                                            />
                                            <div className='checkbox-wrapper' title={'Required to Auto Finalize'}>
                                                <FieldControl
                                                    fieldMetadata={{
                                                        FieldType: SystemTypes.UIFieldType.CheckBox,
                                                        FieldName: "checkbox",
                                                    }}
                                                    className={"checkbox"}
                                                    disableError={true}
                                                    onChange={(value) => {
                                                        updateSourceObjectsOnFieldMap(value, "RequiredAutoFinalize", soOnfieldMap)
                                                    }}
                                                    value={soOnfieldMap.RequiredAutoFinalize}
                                                />
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                                <FieldControl
                                    fieldMetadata={{
                                        FieldName: sourceObject._id,
                                        FieldType: SystemTypes.UIFieldType.TextBox,
                                        PlaceHolder: 'Search'
                                    }}
                                    onChange={(value, fieldName) => {
                                        const copiedSearch = { ...search }
                                        copiedSearch[fieldName] = value;
                                        setSearch(copiedSearch)
                                    }}
                                    value={search[sourceObject._id]}
                                />
                                <div className="field-container vertical-scrollable">
                                    {_.orderBy(_.filter(sourceObject.Fields, x => search[sourceObject._id] ? x.DisplayName.toLowerCase().includes(search[sourceObject._id].toLowerCase()) : true), ['Sequence'], ["asc"]).map((sourceObjectField, fi) => {
                                        return <div key={fi}
                                            className={"field" +
                                                ((selectedSourceObjectField?.SourceObjectFieldId === sourceObjectField.SourceObjectFieldId) ? " active" : "")}
                                            onClick={() => {
                                                onSelectSourceObjectField(sourceObjectField)
                                            }}
                                        >
                                            {sourceObjectField.DisplayName}
                                        </div>
                                    })}
                                </div>

                            </div>
                        })}
                        <div className="sobj-column sobj-column-addnew">
                            <div className="field-container">

                                <FieldControl
                                    className={"sobj-selectlist" + (loadingSourceObject ? " loading-source-object" : "")}
                                    onChange={(v) => {
                                        setLoadingSourceObject(true)
                                        sourceObjectMgr.getItem(v).then(r => {
                                            const sourceObject = r.Items.first()
                                            setDataDTI((prevValue) => {
                                                prevValue.SourceObjects.push(sourceObject)
                                                return prevValue
                                            })
                                            setLoadingSourceObject(false)

                                        })
                                    }}
                                    fieldMetadata={{
                                        FieldName: "addSourceObject",
                                        FieldType: SystemTypes.UIFieldType.SearchSelectList,
                                        DataJson: sourceObjectSelectData
                                    }}
                                />
                                {loadingSourceObject && <Loading />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <ButtonControl
                        type={'okay'}
                        onClick={async () => {
                            await Promise.all(fieldMaps.map(async (x) => {
                                await entityObjectFieldMapMgr.saveItem(x);
                            }))
                        }}
                    >Save</ButtonControl>
                </div></> :
                <div className="loading-wrapper">
                    <Loading />
                </div>
            }
        </div >


    );
}

export default EntityObjectFieldMapping;



