/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import {
     BOAddressItemService         
     ,BOAssetItemService         
     ,BOAssetTypeItemService         
     ,BOCustomerItemService         
     ,BOCustomerPropertyItemService         
     ,BOCustomerTagItemService         
     ,BOEmployeeItemService         
     ,BOInventoryProductItemService         
     ,BOInvoiceItemService         
     ,BOJobItemService         
     ,BOJobTypeItemService         
     ,BOLaborRateGroupsItemService         
     ,BOLaborTypeItemService         
     ,BOPayrollHourTypeItemService         
     ,BOProductItemService         
     ,BOProjectItemService         
     ,BOPurchaseOrderItemService         
     ,BOPurchaseOrderReceiptItemService         
     ,BOQuoteItemService         
     ,BOVendorItemService         
     ,BOVisitItemService         
     ,ConvokeAppConfigItemService         
     ,DataTypeItemService         
     ,EntityMasterRecordItemService         
     ,EntityMasterRecordMapItemService         
     ,EntityObjectFieldMapItemService         
     ,EntityObjectItemService         
     ,EntityQueryItemService         
     ,SchemaItemService         
     ,IntegrationItemService         
     ,SourceObjectItemService         
     ,SourceObjectRecordItemService         
     ,SourceSystemItemService         
     ,DirtyRecordDeploymentItemService         
     ,SchemaAPIItemService         
     ,SchemaDestinationLogItemService         
     ,WarehouseItemService         
     ,AgreementNumberItemService         
     ,ExternalAppItemService         
     ,FranchiseeExternalAppSettingItemService         
     ,FranchiseeLocationItemService         
     ,OnboardingItemService         
     ,ProposalNumberItemService
     ,TrainingEmailItemService
     ,SageVendorItemService
} from './ItemServices';

import { ItemServiceFactory as BaseItemServiceFactory } from 'spark-core-dx/services'
    
export class ItemServiceFactory {
     
     get  ItemServiceEnum() {
          return {
               ...BaseItemServiceFactory.ItemServiceEnum,
         BOAddress:"BOAddress",
         BOAsset:"BOAsset",
         BOAssetType:"BOAssetType",
         BOCustomer:"BOCustomer",
         BOCustomerProperty:"BOCustomerProperty",
         BOCustomerTag:"BOCustomerTag",
         BOEmployee:"BOEmployee",
         BOInventoryProduct:"BOInventoryProduct",
         BOInvoice:"BOInvoice",
         BOJob:"BOJob",
         BOJobType:"BOJobType",
         BOLaborRateGroups:"BOLaborRateGroups",
         BOLaborType:"BOLaborType",
         BOPayrollHourType:"BOPayrollHourType",
         BOProduct:"BOProduct",
         BOProject:"BOProject",
         BOPurchaseOrder:"BOPurchaseOrder",
         BOPurchaseOrderReceipt:"BOPurchaseOrderReceipt",
         BOQuote:"BOQuote",
         BOVendor:"BOVendor",
         BOVisit:"BOVisit",
         ConvokeAppConfig:"ConvokeAppConfig",
         DataType:"DataType",
         EntityMasterRecord:"EntityMasterRecord",
         EntityMasterRecordMap:"EntityMasterRecordMap",
         EntityObjectFieldMap:"EntityObjectFieldMap",
         EntityObject:"EntityObject",
         EntityQuery:"EntityQuery",
         Schema:"Schema",
         Integration:"Integration",
         SourceObject:"SourceObject",
         SourceObjectRecord:"SourceObjectRecord",
         SourceSystem:"SourceSystem",
         DirtyRecordDeployment:"DirtyRecordDeployment",
         SchemaAPI:"SchemaAPI",
         SchemaDestinationLog:"SchemaDestinationLog",
         Warehouse:"Warehouse",
         AgreementNumber:"AgreementNumber",
         ExternalApp:"ExternalApp",
         FranchiseeExternalAppSetting:"FranchiseeExternalAppSetting",
         FranchiseeLocation:"FranchiseeLocation",
         Onboarding:"Onboarding",
         ProposalNumber:"ProposalNumber",
         TrainingEmail:"TrainingEmail",
         SageVendor:"SageVendor"
               }
           }
     
      GetItemService(itemServiceName, coreState, coreContext, isWarning) {
         if(itemServiceName === undefined) throw new Error('Item service name not found, please regenerate the Item Services to resolve this issue.');
         let itemService;
         switch(itemServiceName.toLowerCase()){
             case this.ItemServiceEnum.BOAddress.toLowerCase():
                  itemService = new BOAddressItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOAsset.toLowerCase():
                  itemService = new BOAssetItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOAssetType.toLowerCase():
                  itemService = new BOAssetTypeItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOCustomer.toLowerCase():
                  itemService = new BOCustomerItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOCustomerProperty.toLowerCase():
                  itemService = new BOCustomerPropertyItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOCustomerTag.toLowerCase():
                  itemService = new BOCustomerTagItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOEmployee.toLowerCase():
                  itemService = new BOEmployeeItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOInventoryProduct.toLowerCase():
                  itemService = new BOInventoryProductItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOInvoice.toLowerCase():
                  itemService = new BOInvoiceItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOJob.toLowerCase():
                  itemService = new BOJobItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOJobType.toLowerCase():
                  itemService = new BOJobTypeItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOLaborRateGroups.toLowerCase():
                  itemService = new BOLaborRateGroupsItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOLaborType.toLowerCase():
                  itemService = new BOLaborTypeItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOPayrollHourType.toLowerCase():
                  itemService = new BOPayrollHourTypeItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOProduct.toLowerCase():
                  itemService = new BOProductItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOProject.toLowerCase():
                  itemService = new BOProjectItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOPurchaseOrder.toLowerCase():
                  itemService = new BOPurchaseOrderItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOPurchaseOrderReceipt.toLowerCase():
                  itemService = new BOPurchaseOrderReceiptItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOQuote.toLowerCase():
                  itemService = new BOQuoteItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOVendor.toLowerCase():
                  itemService = new BOVendorItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.BOVisit.toLowerCase():
                  itemService = new BOVisitItemService(coreState, coreContext, isWarning);
                  break;
          case this.ItemServiceEnum.SageVendor.toLowerCase():
               itemService = new SageVendorItemService(coreState, coreContext, isWarning);
               break;
             case this.ItemServiceEnum.ConvokeAppConfig.toLowerCase():
                  itemService = new ConvokeAppConfigItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.DataType.toLowerCase():
                  itemService = new DataTypeItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.EntityMasterRecord.toLowerCase():
                  itemService = new EntityMasterRecordItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.EntityMasterRecordMap.toLowerCase():
                  itemService = new EntityMasterRecordMapItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.EntityObjectFieldMap.toLowerCase():
                  itemService = new EntityObjectFieldMapItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.EntityObject.toLowerCase():
                  itemService = new EntityObjectItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.EntityQuery.toLowerCase():
                  itemService = new EntityQueryItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.Schema.toLowerCase():
                  itemService = new SchemaItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.Integration.toLowerCase():
                  itemService = new IntegrationItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.SourceObject.toLowerCase():
                  itemService = new SourceObjectItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.SourceObjectRecord.toLowerCase():
                  itemService = new SourceObjectRecordItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.SourceSystem.toLowerCase():
                  itemService = new SourceSystemItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.DirtyRecordDeployment.toLowerCase():
                  itemService = new DirtyRecordDeploymentItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.SchemaAPI.toLowerCase():
                  itemService = new SchemaAPIItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.SchemaDestinationLog.toLowerCase():
                  itemService = new SchemaDestinationLogItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.Warehouse.toLowerCase():
                  itemService = new WarehouseItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.AgreementNumber.toLowerCase():
                  itemService = new AgreementNumberItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.ExternalApp.toLowerCase():
                  itemService = new ExternalAppItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.FranchiseeExternalAppSetting.toLowerCase():
                  itemService = new FranchiseeExternalAppSettingItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.FranchiseeLocation.toLowerCase():
                  itemService = new FranchiseeLocationItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.Onboarding.toLowerCase():
                  itemService = new OnboardingItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.ProposalNumber.toLowerCase():
                  itemService = new ProposalNumberItemService(coreState, coreContext, isWarning);
                  break;
             case this.ItemServiceEnum.TrainingEmail.toLowerCase():
                  itemService = new TrainingEmailItemService(coreState, coreContext, isWarning);
                  break;
                  
             default:
               itemService = BaseItemServiceFactory.GetItemService(itemServiceName, coreState, coreContext);
               break;
             }
             return itemService;
         }
     
         ValidateItemName(itemName) {
          let isValid = false;
          for (const key in this.ItemServiceEnum) {
               if (typeof (this.ItemServiceEnum[key]) === "string" &&
                    this.ItemServiceEnum[key].toLowerCase() == itemName.toLowerCase()) {
                    isValid = true;
                    break;
               }
          }
          return BaseItemServiceFactory.ValidateItemName(itemName);
      }
     }
     